<template>
  <LayoutPageStepper
    analytics-name="send-money"
    :steps="steps"
    :active-step-idx="activeStepIdx"
    logo-href="/activity"
    @logoClick="onLogoClick"
  >
    <template #navRight>
      <AppButton
        v-if="$route.name === 'SendMoneySummary' && mq.current === 'xs'"
        theme="icon"
        analytics-name="send-summary-edit"
        @click="edit"
      >
        <AppIcon name=" ">
          <IconEdit />
        </AppIcon>
      </AppButton>
      <AppBackButton v-else analytics-name="send-money-close" @click="close" />
    </template>
    <!--Display Child Route Form-->
    <RouterView />

    <template #overlays>
      <VerifyNotSuppliedModal />
      <!-- [TODO] refactor to a new componente -->
      <AppDialogConfirmation
        class="verification"
        v-model="showLeaveConfirmationDialog"
        analytics-name="send-verify-identity-onfido-leave-confirmation"
        :title="$t('PageSendMoneyVerifyIdentityCancel.Title').value"
        :cancel-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonYes').value"
        :confirm-btn-text="$t('PageSendMoneyVerifyIdentityCancel.ButtonNo').value"
        @cancel="cancelOnfido"
        @confirm="showLeaveConfirmationDialog = false"
      >
        <template #default>
          <p>{{ $t('PageSendMoneyVerifyIdentityCancel.Message').value }}</p>
        </template>

        <template #footer>
          <div class="card-footer px-5">
            <AppButton class="my-2" type="button" theme="secondary" @click="cancelOnfido">
              {{ $t('PageSendMoneyVerifyIdentityCancel.ButtonYes').value }}
            </AppButton>
            <AppButton class="my-2" type="button" @click="showLeaveConfirmationDialog = false">
              {{ $t('PageSendMoneyVerifyIdentityCancel.ButtonNo').value }}
            </AppButton>
          </div>
        </template>
      </AppDialogConfirmation>
    </template>
  </LayoutPageStepper>
</template>

<script>
import { onUnmounted, ref, computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'

import LayoutPageStepper from '@galileo/components/LayoutPageStepper/LayoutPageStepper'
import { IconEdit } from '@oen.web.vue2/icons'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  useMediaQuery,
  AppBackButton,
  AppDialogConfirmation,
  AppButton,
  AppIcon,
} from '@oen.web.vue2/ui'

import VerifyNotSuppliedModal from '@galileo/components/Views/SendMoney/VerifyNotSuppliedModal'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

import { useI18nStore, useSendMoneyStore, useAnalyticsStore } from '@galileo/stores'
import useOpenBanking from '@galileo/composables/useOpenBanking'

export default {
  name: 'SendMoney',
  components: {
    AppBackButton,
    LayoutPageStepper,
    AppDialogConfirmation,
    AppButton,
    AppIcon,
    IconEdit,
    VerifyNotSuppliedModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const mq = useMediaQuery()
    const sendMoneyStore = useSendMoneyStore()

    const showLeaveConfirmationDialog = ref(false)

    const steps = computed(() => sendMoneyStore.getAvailableSteps)

    const activeStepIdx = computed(() => sendMoneyStore.activeStepIdx)
    const activeStep = computed(() => sendMoneyStore.activeStep)
    const { isPayTo, cancelCheckout } = useOpenBanking()
    const cancelOnfido = async () => {
      await router.goBackTo(
        {
          name: STEPS.SEND_MONEY_SUMMARY,
        },
        true
      )
      showLeaveConfirmationDialog.value = false
    }

    const close = async () => {
      let currentRoute = router.currentRoute

      // Alter "Close" behavior for Onfido only
      if (['SendMoneyVerifyIdentity', 'SendMoneyOnfido'].includes(currentRoute.name)) {
        showLeaveConfirmationDialog.value = true
      } else if (['SendMoneyVolt'].includes(currentRoute.name) && !isPayTo.value) {
        cancelCheckout()
      } else {
        sendMoneyStore.clearCardData() // to be on the safe side try to get rid of it
        analyticsStore.track({
          event: SEGMENT_EVENTS.QUOTE_CANCELLED,
          traits: {},
        })
        await router.replace({
          name: 'Activity',
        })
      }
    }

    const onLogoClick = async ({ $event }) => {
      // TODO - Should this behavior the same as close when in onfido
      $event.preventDefault()
      sendMoneyStore.restoreDefaultForm()
      await router.push(
        {
          name: 'Activity',
        },
        true
      )
    }

    const edit = async () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.TRANSACTION_EDIT,
        traits: {
          location: SEGMENT_LOCATIONS.TRANSACTION_SUMMARY,
        },
      })

      await router.push(
        {
          name: 'SendMoneySummaryEdit',
        },
        true
      )
      // const route = {
      //   name: 'SendMoneyAmount',
      // }
      // const success = await router.goBackTo(route)
      // if (!success) {
      //   router.push(route)
      // }
    }

    onUnmounted(async () => {
      sendMoneyStore.clearRefreshQuote()
    })

    return {
      mq,
      steps,
      close,
      activeStepIdx,
      activeStep,
      $t,
      showLeaveConfirmationDialog,
      cancelOnfido,
      edit,
      onLogoClick,
    }
  },
}
</script>

<style>
::-webkit-scrollbar-track {
  background-color: white !important;
}
</style>
