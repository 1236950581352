<template>
  <div class="layout-page-stepper" :class="classes">
    <XeNavBar
      ref="navBar"
      class="layout-page-stepper-navigation"
      :logo-href="logoHref"
      @logoClick="$emit('logoClick', $event)"
    >
      <!--Left-->
      <template v-if="mq.current === 'xs' && activeStepIdx > 0" #left>
        <AppBackButton analytics-name="stepper-back-button" icon="<" @click="navigateBack" />
      </template>
      <!--Center-->
      <template v-if="mq.current === 'xs'">
        {{ layoutPageStepper.title }}
      </template>
      <AppNavBarStepper :steps="steps" :active-step-idx="activeStepIdx" />
      <!--Right-->
      <template v-if="$slots.navRight" #right>
        <!--@slot navRight: right side of the navbar-->
        <slot name="navRight"></slot>
      </template>
    </XeNavBar>

    <div class="layout-page-stepper-content">
      <!-- @slot default: any content, however, developed in mind to host <AppCard>  -->
      <slot />
    </div>

    <slot name="overlays" />
  </div>
</template>
<script>
import { computed, provide, reactive } from '@vue/composition-api'
import XeNavBar from '@galileo/components/XeNavBar/XeNavBar'

import { AppNavBarStepper, AppBackButton, useMediaQuery } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'

const maxSteps = 5
export default {
  name: 'LayoutPageStepper',
  components: {
    AppBackButton,
    XeNavBar,
    AppNavBarStepper,
  },
  emits: ['logoClick'],
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => [],
      validation: (val) => {
        return val.length > 0 && val.length <= maxSteps
      },
    },
    activeStepIdx: {
      type: Number,
      default: 0,
      validation: (val) => {
        return val <= maxSteps - 1
      },
    },
    logoHref: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const mq = useMediaQuery()
    const router = useRouter()
    const layoutPageStepper = reactive({
      // layoutPageStepper injected in XeStepperCardHeader
      navBack: null,
      title: '',
      steps: computed(() => props.steps),
      activeStepIdx: computed(() => props.activeStepIdx),
    })

    provide('layoutPageStepper', layoutPageStepper)

    const classes = computed(() => ({
      'layout-page-stepper--xs': mq.current === 'xs',
    }))

    const navigateBack = () => {
      if (layoutPageStepper.navBack) {
        layoutPageStepper.navBack()
      } else {
        router.go(-1)
      }
    }

    return { mq, classes, layoutPageStepper, navigateBack }
  },
}
</script>
<style scoped>
.layout-page-stepper {
  &.layout-page-stepper--xs {
    @apply w-full h-full;
    @apply flex flex-col;

    .layout-page-stepper-content {
      @apply flex;
      @apply h-full;
      @apply overflow-y-auto;

      > *:first-child,
      ::v-deep > *:first-child {
        @apply w-full;
      }
    }
  }
}
</style>
