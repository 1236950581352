<template>
  <InformationAppModal :value="showVerifyNotSuppliedDialog" icon="phoneRed">
    <h1 class="title">{{ $t('VerifyNotSuppliedModal.Title').value }}</h1>
    <p>
      {{ $t('VerifyNotSuppliedModal.Text').value }}
    </p>
    <template #footer>
      <AppButton @click="openLink">
        {{ $t('VerifyNotSuppliedModal.GetInTouchText').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeVerifyNotSuppliedDialog">
        {{ $t('VerifyNotSuppliedModal.CloseText').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'
import { computed, watch } from '@vue/composition-api'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton } from '@oen.web.vue2/ui'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  useI18nStore,
  useAnalyticsStore,
  useProfileStore,
  useAuthStore,
  useSendMoneyStore,
} from '@galileo/stores'

export default {
  name: 'VerifyNotSuppliedModal',
  components: {
    InformationAppModal,
    AppButton,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const { $t } = useI18nStore()
    const router = useRouter()
    const profileStore = useProfileStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()

    const { isCorporateAccount } = authStore

    const transferBasicResponse = computed(() => {
      return sendMoneyStore.transferBasicResponse
    })

    const { profileUpdateErrors } = profileStore

    const showVerifyNotSuppliedDialog = computed(() => {
      if (!isCorporateAccount) return false
      const shouldShowModal = sendMoneyStore.showVerifyNotSuppliedModal

      let result = false

      if (transferBasicResponse.value?.errors && shouldShowModal) {
        result = transferBasicResponse.value.errors
          .filter(
            (err) => !['corpIndustry', 'corpSubIndustry'].includes(err.id)
          )
          .some(
            (err) => err.reasonCode === 'Verify_NotSupplied'
          )
      } else if (profileUpdateErrors) {
        result = profileUpdateErrors.some((err) => err.reasonCode === 'Verify_NotSupplied')
      }

      return result
    })

    const closeVerifyNotSuppliedDialog = () => {
      sendMoneyStore.showVerifyNotSuppliedModal = null

      if (profileUpdateErrors) {
        profileStore.profileUpdateErrors = null
      } else {
        router.replace('/send-money')
      }
    }

    const openLink = () => {
      const url = 'https://help.xe.com/hc/p/contact-details'
      window.open(url)
    }

    watch(showVerifyNotSuppliedDialog, (value) => {
      if (value) {
        let errors = null
        let location = null
        if (profileUpdateErrors) {
          errors = profileUpdateErrors.map((val) => val.id).join(',')
          location = SEGMENT_LOCATIONS.ACCOUNT
        } else {
          errors = transferBasicResponse.value.errors.map((val) => val.id).join(',')
          location = SEGMENT_LOCATIONS.SEND_MONEY
        }
        analyticsStore.track({
          event: SEGMENT_EVENTS.KYS_MISSING_INFORMATION_PROMPT_DISPLAYED,
          traits: {
            location: location,
            reasonCode: errors,
          },
        })
      }
    })

    return {
      $t,
      showVerifyNotSuppliedDialog,
      closeVerifyNotSuppliedDialog,
      openLink,
    }
  },
}
</script>
